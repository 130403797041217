import { Result } from 'antd'
import React from 'react'

export const Error404Template: React.FC = () => (
  <Result
    status="404"
    title="お探しのページが見つかりません"
    subTitle="お探しのページは移動・削除されたか、入力したURLに誤りがある可能性があります。"
  />
)
